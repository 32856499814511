import React from 'react';
import { Container, Row, Col, Button, Alert, Card } from 'react-bootstrap';
import { InfoCircleFill, EnvelopeFill, Whatsapp, FileTextFill, ClockFill, ClipboardCheckFill, PeopleFill } from 'react-bootstrap-icons';
import 'bootstrap/dist/css/bootstrap.min.css';

const InternshipInfo: React.FC = () => {
  return (
    <Container style={{ marginTop: '100px' }}>
      <Row>
        <Col md={6}>
          <Alert
            variant="info"
            className="text-start mb-5 p-4 rounded shadow-lg"
            style={{
              background: 'linear-gradient(135deg, #f8f9fa, #e9ecef)',
              border: '1px solid #dee2e6',
              transition: 'transform 0.3s, box-shadow 0.3s',
              cursor: 'pointer',
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = 'scale(1.05)';
              e.currentTarget.style.boxShadow = '0 0 20px rgba(0, 0, 0, 0.2)';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = 'scale(1)';
              e.currentTarget.style.boxShadow = 'none';
            }}
          >
            <h4
              className="alert-heading"
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '1.5rem',
                fontWeight: 'bold',
                marginBottom: '0.5rem',
                color: '#343a40',
              }}
            >
              <InfoCircleFill style={{ marginRight: '0.5rem' }} />  Graduate Research Internship
            </h4>
            <hr />
            <Card className="mb-3">
              <Card.Body>
                <Card.Title style={{ display: 'flex', alignItems: 'center', fontSize: '1.2rem' }}>
                  <FileTextFill style={{ marginRight: '0.5rem', color: '#17a2b8' }} /> Qualification
                </Card.Title>
                <Card.Text>Bachelor/Master (Biological Sciences, Physical Sciences, Chemical Sciences, Mathematical Sciences, Environmental Sciences)</Card.Text>
              </Card.Body>
            </Card>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title style={{ display: 'flex', alignItems: 'center', fontSize: '1.2rem' }}>
                  <ClockFill style={{ marginRight: '0.5rem', color: '#28a745' }} /> Duration
                </Card.Title>
                <Card.Text>6 Months</Card.Text>
              </Card.Body>
            </Card>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title style={{ display: 'flex', alignItems: 'center', fontSize: '1.2rem' }}>
                  <ClipboardCheckFill style={{ marginRight: '0.5rem', color: '#ffc107' }} /> Weekly
                </Card.Title>
                <Card.Text>10 Hours (Part-Time)</Card.Text>
              </Card.Body>
            </Card>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title style={{ display: 'flex', alignItems: 'center', fontSize: '1.2rem' }}>
                  <ClipboardCheckFill style={{ marginRight: '0.5rem', color: '#007bff' }} /> Modules
                </Card.Title>
                <Row>
                  <Col>
                    <Card className="mb-2" style={{ borderColor: '#17a2b8' }}>
                      <Card.Body>
                        <Card.Title style={{ color: '#17a2b8', fontWeight: 'bold' }}>Literature-Review</Card.Title>
                        <Card.Text>2 Months</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="mb-2" style={{ borderColor: '#28a745' }}>
                      <Card.Body>
                        <Card.Title style={{ color: '#28a745', fontWeight: 'bold' }}>Lab-Experiment</Card.Title>
                        <Card.Text>2 Months</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="mb-2" style={{ borderColor: '#ffc107' }}>
                      <Card.Body>
                        <Card.Title style={{ color: '#ffc107', fontWeight: 'bold' }}>Paper-Publication</Card.Title>
                        <Card.Text>2 Months</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title style={{ display: 'flex', alignItems: 'center', fontSize: '1.2rem' }}>
                  <PeopleFill style={{ marginRight: '0.5rem', color: '#6f42c1' }} /> Mode
                </Card.Title>
                <Card.Text>Hybrid (Online + Offline)</Card.Text>
              </Card.Body>
            </Card>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title style={{ display: 'flex', alignItems: 'center', fontSize: '1.2rem' }}>
                  <ClipboardCheckFill style={{ marginRight: '0.5rem', color: '#fd7e14' }} /> Selection
                </Card.Title>
                <Card.Text>Written/Interview</Card.Text>
              </Card.Body>
            </Card>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title style={{ display: 'flex', alignItems: 'center', fontSize: '1.2rem' }}>
                  <ClipboardCheckFill style={{ marginRight: '0.5rem', color: '#dc3545' }} /> Fees
                </Card.Title>
                <Card.Text>INR 1000 | USD 100 (For Foreigners)</Card.Text>
              </Card.Body>
            </Card>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title style={{ display: 'flex', alignItems: 'center', fontSize: '1.2rem' }}>
                  <ClipboardCheckFill style={{ marginRight: '0.5rem', color: '#ffc107' }} /> Stipend
                </Card.Title>
                <Card.Text>Nil</Card.Text>
              </Card.Body>
            </Card>
            <div className="d-flex justify-content-end">
              <Button
                variant="dark"
                className="me-2"
                href="https://forms.gle/oTMGagYHsTeoy1PQ6"
                style={{ fontWeight: 'bold', backgroundColor: '#17a2b8', borderColor: '#17a2b8', transition: 'transform 0.3s' }}
                onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
              >
                Apply Now
              </Button>
              <Button
                variant="dark"
                className="me-2"
                href="mailto:contact@giarindia.com"
                style={{ fontWeight: 'bold', backgroundColor: '#b8860b', borderColor: '#b8860b', transition: 'transform 0.3s' }}
                onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
              >
                <EnvelopeFill className="me-1" /> Email Us
              </Button>
              <Button
                variant="dark"
                href="whatsapp://send?phone=919981964371"
                style={{ fontWeight: 'bold', backgroundColor: '#25D366', borderColor: '#25D366', transition: 'transform 0.3s' }}
                onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
              >
                <Whatsapp className="me-1" /> WhatsApp
              </Button>
            </div>
          </Alert>
        </Col>

        <Col md={6}>
          <Alert
            variant="info"
            className="text-start mb-5 p-4 rounded shadow-lg"
            style={{
              background: 'linear-gradient(135deg, #f8f9fa, #e9ecef)',
              border: '1px solid #dee2e6',
              transition: 'transform 0.3s, box-shadow 0.3s',
              cursor: 'pointer',
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = 'scale(1.05)';
              e.currentTarget.style.boxShadow = '0 0 20px rgba(0, 0, 0, 0.2)';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = 'scale(1)';
              e.currentTarget.style.boxShadow = 'none';
            }}
          >
            <h4
              className="alert-heading"
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '1.5rem',
                fontWeight: 'bold',
                marginBottom: '0.5rem',
                color: '#343a40',
              }}
            >
              <InfoCircleFill style={{ marginRight: '0.5rem' }} /> Doctoral Research Internship
            </h4>
            <hr />
            <Card className="mb-3">
              <Card.Body>
                <Card.Title style={{ display: 'flex', alignItems: 'center', fontSize: '1.2rem' }}>
                  <FileTextFill style={{ marginRight: '0.5rem', color: '#17a2b8' }} /> Qualification
                </Card.Title>
                <Card.Text>Master/Doctoral (Biological Sciences, Physical Sciences, Chemical Sciences, Mathematical Sciences, Environmental Sciences)</Card.Text>
              </Card.Body>
            </Card>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title style={{ display: 'flex', alignItems: 'center', fontSize: '1.2rem' }}>
                  <ClockFill style={{ marginRight: '0.5rem', color: '#28a745' }} /> Duration
                </Card.Title>
                <Card.Text>1 Year</Card.Text>
              </Card.Body>
            </Card>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title style={{ display: 'flex', alignItems: 'center', fontSize: '1.2rem' }}>
                  <ClipboardCheckFill style={{ marginRight: '0.5rem', color: '#ffc107' }} /> Weekly
                </Card.Title>
                <Card.Text>10 Hours (Part-Time)</Card.Text>
              </Card.Body>
            </Card>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title style={{ display: 'flex', alignItems: 'center', fontSize: '1.2rem' }}>
                  <ClipboardCheckFill style={{ marginRight: '0.5rem', color: '#007bff' }} /> Modules
                </Card.Title>
                <Row>
                  <Col>
                    <Card className="mb-2" style={{ borderColor: '#17a2b8' }}>
                      <Card.Body>
                        <Card.Title style={{ color: '#17a2b8', fontWeight: 'bold' }}>Literature-Review</Card.Title>
                        <Card.Text>4 Months</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="mb-2" style={{ borderColor: '#28a745' }}>
                      <Card.Body>
                        <Card.Title style={{ color: '#28a745', fontWeight: 'bold' }}>Lab-Experiment</Card.Title>
                        <Card.Text>4 Months</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="mb-2" style={{ borderColor: '#ffc107' }}>
                      <Card.Body>
                        <Card.Title style={{ color: '#ffc107', fontWeight: 'bold' }}>Paper-Publication</Card.Title>
                        <Card.Text>4 Months</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title style={{ display: 'flex', alignItems: 'center', fontSize: '1.2rem' }}>
                  <PeopleFill style={{ marginRight: '0.5rem', color: '#6f42c1' }} /> Mode
                </Card.Title>
                <Card.Text>Hybrid (Online + Offline)</Card.Text>
              </Card.Body>
            </Card>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title style={{ display: 'flex', alignItems: 'center', fontSize: '1.2rem' }}>
                  <ClipboardCheckFill style={{ marginRight: '0.5rem', color: '#fd7e14' }} /> Selection
                </Card.Title>
                <Card.Text>Written/Interview</Card.Text>
              </Card.Body>
            </Card>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title style={{ display: 'flex', alignItems: 'center', fontSize: '1.2rem' }}>
                  <ClipboardCheckFill style={{ marginRight: '0.5rem', color: '#dc3545' }} /> Fees
                </Card.Title>
                <Card.Text>INR 10000 | USD 1000 (For Foreigners)</Card.Text>
              </Card.Body>
            </Card>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title style={{ display: 'flex', alignItems: 'center', fontSize: '1.2rem' }}>
                  <ClipboardCheckFill style={{ marginRight: '0.5rem', color: '#ffc107' }} /> Stipend
                </Card.Title>
                <Card.Text>Nil</Card.Text>
              </Card.Body>
            </Card>
            <div className="d-flex justify-content-end">
              <Button
                variant="dark"
                className="me-2"
                href="https://forms.gle/oTMGagYHsTeoy1PQ6"
                style={{ fontWeight: 'bold', backgroundColor: '#17a2b8', borderColor: '#17a2b8', transition: 'transform 0.3s' }}
                onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
              >
                Apply Now
              </Button>
              <Button
                variant="dark"
                className="me-2"
                href="mailto:contact@giarindia.com"
                style={{ fontWeight: 'bold', backgroundColor: '#b8860b', borderColor: '#b8860b', transition: 'transform 0.3s' }}
                onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
              >
                <EnvelopeFill className="me-1" /> Email Us
              </Button>
              <Button
                variant="dark"
                href="whatsapp://send?phone=919981964371"
                style={{ fontWeight: 'bold', backgroundColor: '#25D366', borderColor: '#25D366', transition: 'transform 0.3s' }}
                onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
              >
                <Whatsapp className="me-1" /> WhatsApp
              </Button>
            </div>
          </Alert>
        </Col>
      </Row>
    </Container>
  );
};

export default InternshipInfo;
