import React from 'react';
import { Navbar } from 'react-bootstrap';
import { Facebook, Twitter, Google, Instagram, Linkedin, Github } from 'react-bootstrap-icons';

function Footer() {
    return (
        <div>
            <footer className="text-center">
                <div className="container p-4 pb-0">
                    <section className="mb-4">
                        <a
                            className="btn text-white btn-floating m-1"
                            style={{ backgroundColor: "#3b5998" }}
                            href="#"
                            role="button"
                        >
                            <Facebook />
                        </a>
                        <a
                            className="btn text-white btn-floating m-1"
                            style={{ backgroundColor: "#55acee" }}
                            href="#"
                            role="button"
                        >
                            <Twitter />
                        </a>
                        <a
                            className="btn text-white btn-floating m-1"
                            style={{ backgroundColor: "#dd4b39" }}
                            href="#"
                            role="button"
                        >
                            <Google />
                        </a>
                        <a
                            className="btn text-white btn-floating m-1"
                            style={{ backgroundColor: "#ac2bac" }}
                            href="#"
                            role="button"
                        >
                            <Instagram />
                        </a>
                        <a
                            className="btn text-white btn-floating m-1"
                            style={{ backgroundColor: "#0082ca" }}
                            href="#"
                            role="button"
                        >
                            <Linkedin />
                        </a>
                        <a
                            className="btn text-white btn-floating m-1"
                            style={{ backgroundColor: "#333333" }}
                            href="#"
                            role="button"
                        >
                            <Github />
                        </a>
                    </section>
                </div>
                <div className="text-center p-3" style={{ fontWeight: "700" }}>
                    © 2024 Copyright :
                    <a className="text-body" href="#">GoldSmith Institute of Advanced Research</a>
                </div>
            </footer>
        </div>
    );
}

export default Footer;