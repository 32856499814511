import React, { useState, ChangeEvent, FormEvent } from 'react';
import { Container, Row, Col, Card, Button, Form, Alert, InputGroup, FormControl, Nav, Tab } from 'react-bootstrap';
import ProjectModal from './ProjectModal';
import { ResearchProject } from './types';
import journalImage1 from '../Images/Journel1.jpeg';
import journalImage2 from '../Images/Journel2.jpeg';
import journalImage3 from '../Images/Journel3.jpeg';
import journalImage4 from '../Images/Journel4.jpeg';
import journalImage5 from '../Images/Journel5.jpeg';
import journalImage6 from '../Images/Journel6.jpeg';
import conferenceImage1 from '../Images/Alpha-wing.jpeg';
import conferenceImage2 from '../Images/Beta-wing.jpeg';
import conferenceImage3 from '../Images/Gamma-wing.jpeg';
import conferenceImage4 from '../Images/Delta-wing.jpeg';
import conferenceImage5 from '../Images/Theta-wing.jpeg';
import bookCover1 from '../Images/book1.jpeg';
import bookCover2 from '../Images/book2.jpeg';
import bookCover3 from '../Images/book3.jpeg';
import bookCover4 from '../Images/book4.jpeg';
import bookCover5 from '../Images/book5.jpeg';
import { GeoAlt, People, PeopleFill } from 'react-bootstrap-icons';
import { AwardFill, PersonFill, CheckCircleFill, CurrencyDollar, PersonBadgeFill } from 'react-bootstrap-icons';
interface AmbassadorsData {
  [category: string]: string[];
}
const initialResearchProjects: ResearchProject[] = [
  {
    id: 1,
    title: 'Mnemonic Coding of Neocortical Activities during REM Sleep',
    location: 'Global',
    description: 'Project by the Directorate of Biological Sciences',
    leadResearcher: 'Dr. John Doe',
  },
  {
    id: 2,
    title: 'High Speed Spacecraft Model for Supercluster System',
    location: 'Remote',
    description: 'Project by the Directorate of Physical Sciences',
    leadResearcher: 'Dr. Jane Smith',
  },
  {
    id: 3,
    title: 'High Fragrance Synthetic Perfume with Fragrance Encoding',
    location: 'Washington D.C.',
    description: 'Project by the Directorate of Chemical Sciences',
    leadResearcher: 'Dr. Emily White',
  },
  {
    id: 4,
    title: 'Possibilities of Wireless Communication in Galactic System',
    location: 'San Francisco',
    description: 'Project by the Directorate of Mathematical Sciences',
    leadResearcher: 'Dr. Sarah Johnson',
  },
  {
    id: 5,
    title: 'Organic Vertical Farming with Aquaponic Model in Urban Areas',
    location: 'New York City',
    description: 'Project by the Directorate of Environmental Sciences',
    leadResearcher: 'Dr. William Brown',
  },
];

const initialPublications = [
  {
    id: 1,
    title: 'Genomics Driven Drugs Discovery',
    coverImage: bookCover1,
    editor: 'Dr. Arli Aditya Parikesit',
    location: ' Indonesia'
  },
  {
    id: 2,
    title: 'Software Development Process Automation',
    coverImage: bookCover2,
    editor: 'Dr. Manuel Mazzara',
    location: ' Russia'
  },
  {
    id: 3,
    title: 'International Virtual Business Development',
    coverImage: bookCover3,
    editor: 'Dr. Bernard Kofi Bonarparte ',
    location: 'Ghana'
  },
  {
    id: 4,
    title: 'Interdisciplinary Trends In Higher Education',
    coverImage: bookCover4,
    editor: 'Dr. Anagha Mahajan',
    location: 'India'
  },
  {
    id: 5,
    title: 'Deep Learning Enabled Cloud Server',
    coverImage: bookCover5,
    editor: 'Dr. Radha Rammohan',
    location: 'India'
  },
];

const initialConferences = [
  {
    id: 1,
    title: 'Alpha-Wing Conference',
    description: 'Focusing on advanced technological developments in Asia.',
    coverImage: conferenceImage1,
  },
  {
    id: 2,
    title: 'Beta-Wing Conference',
    description: 'Highlighting innovations in scientific research in America.',
    coverImage: conferenceImage2,
  },
  {
    id: 3,
    title: 'Gamma-Wing Conference',
    description: 'Exploring sustainable development and technology in Africa.',
    coverImage: conferenceImage3,
  },
  {
    id: 4,
    title: 'Delta-Wing Conference',
    description: 'Fostering scientific collaborations in Europe.',
    coverImage: conferenceImage4,
  },
  {
    id: 5,
    title: 'Theta-Wing Conference',
    description: 'Innovations in science and technology in Oceania.',
    coverImage: conferenceImage5,
  },
];

const initialJournals = [
  {
    id: 1,
    title: 'International Journal of Biological Sciences',
    coverImage: journalImage1,
  },
  {
    id: 2,
    title: 'International Journal of Chemical Sciences',
    coverImage: journalImage2,
  },
  {
    id: 3,
    title: 'International Journal of Environmental Sciences',
    coverImage: journalImage3,
  },
  {
    id: 4,
    title: 'International Journal of Physical Sciences',
    coverImage: journalImage4,
  },
  {
    id: 5,
    title: 'International Journal of Mathematical Sciences',
    coverImage: journalImage5,
  },
  {
    id: 6,
    title: 'International Journal of Interdisciplinary Sciences',
    coverImage: journalImage6,
  },
];
const ambassadorsData: AmbassadorsData = {
  "Research Ambassadors": [
    "Alpha-Wing (Asia)",
    "Beta-Wing (America)",
    "Gamma-Wing (Africa)",
    "Delta-Wing (Europe)",
    "Theta-Wing (Oceania)"
  ],
  "Deputy Research Ambassadors": [
    "India",
    "United States",
    "United Kingdom",
    "Germany",
    "Australia"
  ],
  "Additional Research Ambassadors": [
    "Madhya Pradesh",
    "Maharashtra",
    "Karnataka",
    "Tamil Nadu",
    "Uttar Pradesh"
  ]
};
const awardsData = [
  {
    applicant: "University-Students (Bachelor/Master)",
    criteria: " Innovative-Idea",
    amount: " 10K (Award-Money)",
    eligibility: "GIAR- Professional Membership [Fees: 1K Annual | 10K Lifetime]"
  },
  {
    title: "Investigator-Award",
    applicant: "Research-Scholar (Doctoral/Post-Doctoral)",
    criteria: "Research-Proposal",
    amount: "50K (Award-Money)",
    eligibility: "GIAR- Professional Membership [Fees: 5K Annual | 50K Lifetime]"
  },
  {
    title: "Philosopher-Award",
    applicant: "Working Professional (Professor/Scientist)",
    criteria: "Research-Profile",
    amount: " 100K (Award-Money)",
    eligibility: "GIAR- Professional Membership [Fees: 10K Annual | 100K Lifetime]"
  }
];
const Research: React.FC = () => {
  const [researchProjects, setResearchProjects] = useState<ResearchProject[]>(initialResearchProjects);
  const [filteredProjects, setFilteredProjects] = useState<ResearchProject[]>(initialResearchProjects);
  const [selectedProject, setSelectedProject] = useState<ResearchProject | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [newProject, setNewProject] = useState<Omit<ResearchProject, 'id'>>({
    title: '',
    location: '',
    description: '',
    leadResearcher: '',
  });
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setNewProject({ ...newProject, [name]: value });
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = researchProjects.filter(project =>
      project.title.toLowerCase().includes(value) ||
      project.description.toLowerCase().includes(value) ||
      project.location.toLowerCase().includes(value) ||
      project.leadResearcher.toLowerCase().includes(value)
    );
    setFilteredProjects(filtered);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const updatedProjects = [...researchProjects, { ...newProject, id: researchProjects.length + 1 }];
    setResearchProjects(updatedProjects);
    setFilteredProjects(updatedProjects);
    setNewProject({
      title: '',
      location: '',
      description: '',
      leadResearcher: '',
    });
    setShowAlert(true);
  };

  const handleShowModal = (project: ResearchProject) => {
    setSelectedProject(project);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedProject(null);
  };

  return (
    <Container className="py-5" style={{ marginTop: "85px" }}>
      <h2 className="text-center mb-5" style={{ fontFamily: 'Arial, sans-serif', color: '#333' }}>
        Our International Research Projects
      </h2>

      {showAlert && (
        <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>
          Research project submitted successfully!!
        </Alert>
      )}

      <InputGroup className="mb-4">
        <FormControl
          placeholder="Search projects"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </InputGroup>

      <Tab.Container defaultActiveKey="projects">
        <Row className="justify-content-center" style={{ marginBottom: "100px" }}>
          <Col md={8}>
            <Nav fill variant="tabs" className="nav-tabs-custom">
              <Nav.Item>
                <Nav.Link eventKey="projects" className="nav-link">Projects</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="journals" className="nav-link">Journals</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="publications" className="nav-link">Publications</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="conferences" className="nav-link">Conferences</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="ambassadors" className="nav-link">Ambassadors</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="awards" className="nav-link">Awards</Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="projects">
                <Row className="justify-content-center" style={{ marginTop: "50px" }}>
                  <Col md={8}>
                    <Card className="text-center shadow-lg p-3 mb-5 bg-white rounded">
                      <Card.Body>
                        <Card.Title>About Our Research</Card.Title>
                        <Card.Text>
                          Join our international team and contribute to groundbreaking research
                          that impacts the world. Collaborate with experts from diverse fields and
                          make a difference globally.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                <Row className="justify-content-center" >
                  {filteredProjects.map((project) => (
                    <Col key={project.id} md={6} className="mb-4">
                      <Card className="h-100 shadow-lg">
                        <Card.Body className="d-flex flex-column">
                          <Card.Title className="mb-3">{project.title}</Card.Title>
                          <Card.Text className="flex-grow-1">{project.description}</Card.Text>
                          <Card.Text className="mt-2"><strong>Principal Investigator:</strong> {project.leadResearcher}</Card.Text>
                          <Button
                            variant="primary"
                            className="mt-3"
                            onClick={() => handleShowModal(project)}
                          >
                            Learn More
                          </Button>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Tab.Pane>

              <Tab.Pane eventKey="journals">
                <Row className="justify-content-center" style={{ marginTop: "50px" }}>
                  {initialJournals.map((journal) => (
                    <Col key={journal.id} md={4} className="mb-4">
                      <Card className="shadow-lg">
                        <Card.Img variant="top" src={journal.coverImage} />
                        <Card.Body>
                          <Card.Title>{journal.title}</Card.Title>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Tab.Pane>

              <Tab.Pane eventKey="publications">
                <Row className="justify-content-center" style={{ marginTop: "50px" }}>
                  {initialPublications.map((publication) => (
                    <Col key={publication.id} md={4} className="mb-4">
                      <Card className="shadow-lg">
                        <Card.Img variant="top" src={publication.coverImage} />
                        <Card.Body>
                          <Card.Title>{publication.title}</Card.Title>
                          <Card.Text><strong>Editor:</strong> {publication.editor}</Card.Text>
                          <Card.Text><strong>Location:</strong> {publication.location}</Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Tab.Pane>

              <Tab.Pane eventKey="conferences">
                <Row className="justify-content-center" style={{ marginTop: "50px" }}>
                  {initialConferences.map((conference) => (
                    <Col key={conference.id} md={4} className="mb-4">
                      <Card className="shadow-lg">
                        <Card.Img variant="top" src={conference.coverImage} />
                        <Card.Body>
                          <Card.Title>{conference.title}</Card.Title>
                          <Card.Text>{conference.description}</Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="ambassadors">
                <Container className="my-5">
                  <Row className="justify-content-center">
                    {Object.keys(ambassadorsData).map((category, index) => (
                      <Col md={4} className="mb-4" key={index}>
                        <Card className="h-100 border-0 shadow-sm">
                          <Card.Body className="text-center">
                            <div className="mb-3">
                              {category === "Research Ambassadors" && <PeopleFill className="text-primary display-4" />}
                              {category === "Deputy Research Ambassadors" && <People className="text-primary display-4" />}
                              {category === "Additional Research Ambassadors" && <GeoAlt className="text-primary display-4" />}
                            </div>
                            <Card.Title className="h5 mb-3">{category}</Card.Title>
                            <Card.Text>
                              <ul className="list-unstyled">
                                {ambassadorsData[category].map((ambassador, idx) => (
                                  <li key={idx} className="py-1">{ambassador}</li>
                                ))}
                              </ul>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Container>
              </Tab.Pane>

              <Tab.Pane eventKey="awards">
                <Container className="my-5">
                  <Row className="justify-content-center">
                    {awardsData.map((award, index) => (
                      <Col md={4} className="mb-4" key={index}>
                        <Card className="h-100 border-0 shadow-lg rounded animate__animated animate__fadeInUp">
                          <Card.Body className="p-4">
                            <Card.Title className="h5 mb-3 text-primary">
                              <PersonBadgeFill className="me-2" /> {award.title}
                            </Card.Title>
                            <Card.Text className="mb-2">
                              <PersonFill className="me-2 text-secondary" /> <strong>Applicant:</strong> {award.applicant}
                            </Card.Text>
                            <Card.Text className="mb-2">
                              <CheckCircleFill className="me-2 text-success" /> <strong>Criteria:</strong> {award.criteria}
                            </Card.Text>
                            <Card.Text className="mb-2">
                              <CurrencyDollar className="me-2 text-warning" /> <strong>Amount:</strong> {award.amount}
                            </Card.Text>
                            <Card.Text>
                              <PersonBadgeFill className="me-2 text-info" /> <strong>Eligibility:</strong> {award.eligibility}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Container>
              </Tab.Pane>
              
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
      <ProjectModal
        show={showModal}
        project={selectedProject}
        onHide={handleCloseModal}
      />
    </Container>
  );
};

export default Research;
