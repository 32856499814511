import React from 'react';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import { BriefcaseFill } from 'react-bootstrap-icons';
import Radharam from '../Images/Radharam.jpeg'
import Anagha from '../Images/Anagha.jpeg'
import Brijesh from '../Images/Brijesh.jpeg'
import Sunil from '../Images/Sunil.jpeg'
import Shivam from '../Images/Shivam.jpeg'
import Rohit from '../Images/Rohit.jpeg'
import Bhagyashree from '../Images/Bhagyashree.jpeg'
import Sanjana from '../Images/Sanjana.jpeg'
const professionalMembers = [
  {
    title: "Working Professional",
    fees: "Lifetime INR 100,000",
    benefit: "Principal Investigator",
    icon: <BriefcaseFill />,
    link: "https://example.com/individual-offer-3",
    userName: "Dr. Radha Rammohan",
    userPhoto: Radharam,
    membershipType: "Lifetime",
    location: "Bangalore, Karnataka"
  },
  {
    title: "Working Professional",
    fees: "Lifetime INR 100,000",
    benefit: "Principal Investigator",
    icon: <BriefcaseFill />,
    link: "https://example.com/individual-offer-3",
    userName: "Dr. Anagha Mahajan",
    userPhoto: Anagha,
    membershipType: "Lifetime",
    location: "Pune, Maharashtra"
  }
];

const boardMembers = [
  {
    title: "President",
    userName: "Mr. Brijesh Soni",
    userPhoto: Brijesh,
    location: "International"
  },
  {
    title: "Vice-President",
    userName: "Mr. Rohit Soni",
    userPhoto: Rohit,
    location: "International"
  },
  {
    title: "Secretary General",
    userName: "Mr. Shivam Soni",
    userPhoto: Shivam,
    location: "International"
  },
  {
    title: "Director General",
    userName: "Mr. Sunil Soni",
    userPhoto:Sunil,
    location: "International"
  }
];

const staffMembers = [
  {
    title: "Co-Founder",
    userName: "Miss Karuna Tripathi",
    userPhoto: "https://media.licdn.com/dms/image/D4D03AQEUzH0-TcTiRw/profile-displayphoto-shrink_200_200/0/1664379286573?e=1726099200&v=beta&t=G9S7N1mfMMq12g2ClcvDm632wRtEa-r16w5_-mruq2U",
    location: "Maharashtra,India"
  },
  {
    title: "Chief Executive Officer",
    userName: "Miss Sanjana Gupta",
    userPhoto: Sanjana,
    location: "Himachal,India"
  },
  {
    title: "Human Resource Manager",
    userName: "Miss Bhagyasree Laha",
    userPhoto: Bhagyashree,
    location: "Bengal,India"
  }
];

const advisoryCouncil = [
  { userName: "Hon. Dr. Christoph Gugger", userPhoto: "https://via.placeholder.com/80" },
  { userName: "Hon. Dr. Dimitrios Karras", userPhoto: "https://via.placeholder.com/80" },
  { userName: "Hon. Dr. Arli Aditya Parikshit", userPhoto: "https://via.placeholder.com/80" },
  { userName: "Hon. Dr. Shuchitangshu Chatterji", userPhoto: "https://via.placeholder.com/80" },
  { userName: "Hon. Dr. Ranjit Singh", userPhoto: "https://via.placeholder.com/80" },
  { userName: "Hon. Dr. Ajay Kumar", userPhoto: "https://via.placeholder.com/80" },
  { userName: "Hon. Dr. Rajesh Konnur", userPhoto: "https://via.placeholder.com/80" },
  { userName: "Hon. Dr. Rakesh Taurani", userPhoto: "https://via.placeholder.com/80" },
  { userName: "Hon. Dr. Laxmi Narayan Sharma", userPhoto: "https://via.placeholder.com/80" },
  { userName: "Hon. Dr. Ajit Thakur", userPhoto: "https://via.placeholder.com/80" },
  { userName: "Hon. Dr. Akhil Garg", userPhoto: "https://via.placeholder.com/80" },
  { userName: "Hon. Dr. Balachander Kaliappan", userPhoto: "https://via.placeholder.com/80" },
  { userName: "Hon. Dr. Simmi Madan", userPhoto: "https://via.placeholder.com/80" },
  { userName: "Hon. Dr. Dipti Trivedi", userPhoto: "https://via.placeholder.com/80" },
  { userName: "Hon. Dr. Dipti Chauhan", userPhoto: "https://via.placeholder.com/80" },
  { userName: "Hon. Dr. Shubhrajyoti Mishra", userPhoto: "https://via.placeholder.com/80" },
  { userName: "Hon. Dr. Bharat Raj Jaiswal", userPhoto: "https://via.placeholder.com/80" },
  { userName: "Hon. Dr. Devendra Jatav", userPhoto: "https://via.placeholder.com/80" },
  { userName: "Hon. Dr. Jay Kumar Jain", userPhoto: "https://via.placeholder.com/80" },
  { userName: "Hon. Dr. Shivang Desai", userPhoto: "https://via.placeholder.com/80" }
];

const AboutUs = () => {
  const cardStyle = {
    borderRadius: '10px',
    borderColor: '#FFD700', // Gold color for the border
  };

  const headerStyle = {
    backgroundColor: '#FFD700', // Gold background
    color: '#000', // Black text
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    padding: '10px',
  };

  const listStyle = {
    listStyleType: 'none',
    paddingLeft: '0',
  };
  const headingStyle = {
    color: 'black', // Gold color
    textShadow: '1px 1px 2px #000', // Black text shadow
    fontWeight: 'bold',
    letterSpacing: '1px',
  };
  const listItemStyle = {
    backgroundColor: '#f8f9fa', // Light background for list items
    padding: '8px',
    margin: '5px 0',
    borderRadius: '5px',
    boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
    
  };
  return (
    <Container className="py-3" style={{ marginTop: "85px" }}>
      {/* Volume Sections */}
      <div className="organizational-structure ">
      <h2 className="text-center mb-4" style={headingStyle}>Organizational Structure</h2>

      <Card id="goldsmith-volume-1" className="mt-4 shadow" style={cardStyle}>
        <Card.Header style={headerStyle}>
          <h3 className="mb-0">MODULE-1 [थल/Land]</h3>
        </Card.Header>
        <Card.Body>
          <ul style={listStyle}>
            <li style={listItemStyle}>Alpha-Wing (Asia)</li>
            <li style={listItemStyle}>Beta-Wing (America)</li>
            <li style={listItemStyle}>Gamma-Wing (Africa)</li>
            <li style={listItemStyle}>Delta-Wing (Europe)</li>
            <li style={listItemStyle}>Theta-Wing (Oceania)</li>
          </ul>
        </Card.Body>
      </Card>

      <Card id="goldsmith-volume-11" className="mt-4 shadow" style={cardStyle}>
        <Card.Header style={headerStyle}>
          <h3 className="mb-0">MODULE-2 [नभ/Space]</h3>
        </Card.Header>
        <Card.Body>
          <ul style={listStyle}>
            <li style={listItemStyle}>Kilo-Verse (Whirlpool Galaxy)</li>
            <li style={listItemStyle}>Mega-Verse (Fireworks Galaxy)</li>
            <li style={listItemStyle}>Giga-Verse (Sunflower Galaxy)</li>
            <li style={listItemStyle}>Tera-Verse (Milkyway Galaxy)</li>
            <li style={listItemStyle}>Peta-Verse (Andromeda Galaxy)</li>
          </ul>
        </Card.Body>
      </Card>

      <Card id="goldsmith-volume-111" className="mt-4 shadow" style={cardStyle}>
        <Card.Header style={headerStyle}>
          <h3 className="mb-0">MODULE-3 [जल/Water]</h3>
        </Card.Header>
        <Card.Body>
          <ul style={listStyle}>
            <li style={listItemStyle}>Mono-Trench (Arctic Ocean)</li>
            <li style={listItemStyle}>Di-Trench (Southern Ocean)</li>
            <li style={listItemStyle}>Tri-Trench (Indian Ocean)</li>
            <li style={listItemStyle}>Tetra-Trench (Atlantic Ocean)</li>
            <li style={listItemStyle}>Penta-Trench (Pacific Ocean)</li>
          </ul>
        </Card.Body>
      </Card>
    </div>
      {/* Board Members Section */}
      <Card id="goldsmith-board" className="mt-4 shadow">
        <Card.Body>
          <h3 className="text-center mb-4" style={{ color: '#343a40', fontSize: '24px' }}>Board Members</h3>
          <Row>
            {boardMembers.map((member, idx) => (
              <Col md={6} className="mb-4" key={idx}>
                <Card className="h-100 border-0 shadow-sm rounded">
                  <Card.Body className="text-center p-4">
                    {member.userPhoto && (
                      <div className="mb-3">
                        <Image src={member.userPhoto} roundedCircle style={{ width: '100px', height: '100px', border: '4px solid #f8f9fa' }} />
                      </div>
                    )}
                    <Card.Title className="mb-3" style={{ fontWeight: 'bold', color: '#343a40', fontSize: '18px' }}>{member.title}</Card.Title>
                    <Card.Text className="text-muted mb-3" style={{ fontSize: '18px' }}>
                      <strong>Name:</strong> {member.userName}
                      <br />
                      <strong>Location:</strong> {member.location}
                      <br />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Card.Body>
      </Card>

      {/* Staff Members Section */}
      <Card id="goldsmith-staff" className="mt-4 shadow">
        <Card.Body>
          <h3 className="text-center mb-4" style={{ color: '#343a40', fontSize: '24px' }}>Staff Members</h3>
          <Row>
            {staffMembers.map((member, idx) => (
              <Col md={4} className="mb-4" key={idx}>
                <Card className="h-100 border-0 shadow-sm rounded">
                  <Card.Body className="text-center p-3">
                    {member.userPhoto && (
                      <div className="mb-2">
                        <Image src={member.userPhoto} roundedCircle style={{ width: '80px', height: '80px', border: '3px solid #f8f9fa' }} />
                      </div>
                    )}
                    <Card.Title className="mb-2" style={{ fontWeight: 'bold', color: '#343a40', fontSize: '16px' }}>{member.title}</Card.Title>
                    <Card.Text className="text-muted mb-2" style={{ fontSize: '14px' }}>
                      <strong>Name:</strong> {member.userName}
                      <br />
                      <strong>Location:</strong> {member.location}
                      <br />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Card.Body>
      </Card>

        {/* Advisory Council Section */}
        <Card id="goldsmith-advisory" className="mt-4 shadow">
        <Card.Body>
          <h3 className="text-center mb-4" style={{ color: '#343a40', fontSize: '24px' }}>Advisory Council</h3>
          <div className="scrolling-wrapper-flexbox">
            {advisoryCouncil.map((member, idx) => (
              <Card className="h-100 border-0 shadow-sm rounded m-2" style={{ minWidth: '150px' }} key={idx}>
                <Card.Body className="text-center p-3">
                  {member.userPhoto && (
                    <div className="mb-2">
                      <Image src={member.userPhoto} roundedCircle style={{ width: '60px', height: '60px', border: '2px solid #f8f9fa' }} />
                    </div>
                  )}
                  <Card.Text className="text-muted mb-2" style={{ fontSize: '12px' }}>
                    <strong>Name:</strong> {member.userName}
                  </Card.Text>
                </Card.Body>
              </Card>
            ))}
          </div>
        </Card.Body>
      </Card>

      {/* Professional Members Section */}
      <Card id="goldsmith-professional" className="mt-4 shadow">
        <Card.Body>
          <h3 className="text-center mb-4" style={{ color: '#343a40', fontSize: '24px' }}>Professional Members</h3>
          <Row>
            {professionalMembers.map((member, idx) => (
              <Col md={6} className="mb-4" key={idx}>
                <Card className="h-100 border-0 shadow-sm rounded">
                  <Card.Body className="text-center p-4">
                    {member.userPhoto && (
                      <div className="mb-3">
                        <Image src={member.userPhoto} roundedCircle style={{ width: '100px', height: '100px', border: '4px solid #f8f9fa' }} />
                      </div>
                    )}
                    <Card.Title className="mb-3" style={{ fontWeight: 'bold', color: '#343a40', fontSize: '18px' }}>{member.title}</Card.Title>
                    <Card.Text className="text-muted mb-3" style={{ fontSize: '18px' }}>
                      <strong>Name:</strong> {member.userName}
                      <br />
                      <strong>Membership Type:</strong> {member.membershipType}
                      <br />
                      <strong>Location:</strong> {member.location}
                      <br />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Card.Body>
      </Card>

    
    </Container>
  );
}

export default AboutUs;
