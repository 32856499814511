import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ResearchProject } from './types';

interface ProjectModalProps {
  show: boolean;
  onHide: () => void;
  project: ResearchProject | null;
}

const ProjectModal: React.FC<ProjectModalProps> = ({ show, onHide, project }) => {
  if (!project) return null;

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{project.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p><strong>Location:</strong> {project.location}</p>
        <p><strong>Description:</strong> {project.description}</p>
        <p><strong>Lead Researcher:</strong> {project.leadResearcher}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ProjectModal;
