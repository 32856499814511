import React from 'react';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import { Telephone, Envelope, GeoAlt, Clock } from 'react-bootstrap-icons';

const Contact: React.FC = () => {
  const sectionStyle = {
    paddingTop: '100px',
    paddingBottom: '80px',
  };

  return (
    <Container style={sectionStyle}>
      <h2 className="text-center mb-5">Contact Us</h2>
      
      <Row className="mb-5">
        <Col md={4} className="mb-4">
          <Card className="h-100 shadow">
            <Card.Body className="text-center">
              <Telephone size={50} className="mb-3 text-primary" />
              <Card.Title>Phone</Card.Title>
              <Card.Text>+91 9981964371</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        
        <Col md={4} className="mb-4">
          <Card className="h-100 shadow">
            <Card.Body className="text-center">
              <Envelope size={50} className="mb-3 text-primary" />
              <Card.Title>Email</Card.Title>
              <Card.Text>contact@giarindia.com</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        
        <Col md={4} className="mb-4">
          <Card className="h-100 shadow">
            <Card.Body className="text-center">
              <GeoAlt size={50} className="mb-3 text-primary" />
              <Card.Title>Address</Card.Title>
              <Card.Text>Plot No. 224 Shri Ram Plaza, NH-30, Ramnai, India , PIN-486114</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-5">
      <Col md={12}>
        <Card className="h-100 shadow">
          <Card.Body className="text-center">
            <GeoAlt size={50} className="mb-3 text-primary" />
            <Card.Title>Location</Card.Title>
            <iframe
              title="organization-map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d359.1777045194781!2d81.40771051954296!3d24.55440208207022!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x398459b595fb9f2d%3A0x411c028e5035c20a!2sGoldSmith%20Institute%20of%20Advanced%20Research%20%5BGIAR%5D!5e0!3m2!1sen!2sus!4v1720326087851!5m2!1sen!2sus"
              width="100%"
              height="300"
              style={{ border: 0 }}
              allowFullScreen={false}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </Card.Body>
        </Card>
      </Col>
    </Row>
      <Row className="mb-5">
        <Col md={12}>
          <Card className="h-100 shadow">
            <Card.Body className="text-center">
              <h3>Contact Form</h3>
              <p>If you have any questions, please fill out the form below and we will get back to you as soon as possible.</p>
              <Form>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter your name" />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Enter your email" />
                  </Form.Group>
                </Row>

                <Form.Group className="mb-3" controlId="formGridSubject">
                  <Form.Label>Subject</Form.Label>
                  <Form.Control placeholder="Enter the subject" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGridMessage">
                  <Form.Label>Message</Form.Label>
                  <Form.Control as="textarea" rows={3} placeholder="Enter your message" />
                </Form.Group>

                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Contact;
