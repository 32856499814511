
import './App.css';
import { BrowserRouter as Router, Routes,Route } from 'react-router-dom';
import Home from './Pages/Home';
import Nav from './Pages/Nav';
import Research from './Pages/Research'
import Internship from './Pages/Internship';
import Contact from './Pages/Contact';
import About from './Pages/About';
import Footer from './Pages/Footer'
import ProfessionalMembership from './Pages/ProfessionalMembership';
import Notifications from './Pages/Notifications';
import { useEffect, useState } from 'react';
function App() {
  const [notifications, setNotifications] = useState([
    {
        id: 1,
        title: "Join Our Editorial Team!",
        message: "Applications invited for the position of Editor-in-Chief for our upcoming journals.",
        read: false,
        timestamp: "2024-07-09T10:30:00Z"
    },
    {
        id: 2,
        title: "Urgent Hiring in Delhi/NCR",
        message: "Director (HR), Placement Manager, and Dean Management positions.",
        read: true,
        timestamp: "2024-07-09T11:15:00Z"
    },
    {
        id: 3,
        title: "Exciting Opportunity in Madhya Pradesh",
        message: "Additional Research Ambassador (GIAR: ARA).",
        read: false,
        timestamp: "2024-07-09T12:00:00Z"
    }
]);
const markAsRead = (id: number) => {
    const updatedNotifications = notifications.map(notification =>
        notification.id === id ? { ...notification, read: true } : notification
    );
    setNotifications(updatedNotifications);
};

const deleteNotification = (id: number) => {
    const updatedNotifications = notifications.filter(notification => notification.id !== id);
    setNotifications(updatedNotifications);
};
  

  useEffect(() => {
      // Simulate fetching notifications
      const fetchNotifications = async () => {
          try {
              // Replace with actual fetch logic
              const response = await fetch('api/notifications');
              if (!response.ok) {
                  throw new Error('Failed to fetch notifications');
              }
              const data = await response.json();
              setNotifications(data.notifications); // Assuming API returns notifications array
          } catch (error) {
              console.error('Error fetching notifications:', error);
          }
      };

      fetchNotifications();
  }, []);
  return (
    <Router>
    <Nav/>
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/internship' element={<Internship />} />
      <Route path='/about' element={<About />} />
      <Route path='/contact' element={<Contact />} />
      <Route path='/research' element={<Research/>} />
      <Route path="/professional-membership" element={<ProfessionalMembership />} />
      <Route path="/notifications" element={ <Notifications notifications={notifications} markAsRead={markAsRead} deleteNotification={deleteNotification} />} />
    </Routes>
    <Footer/>
  </Router>
  );
}

export default App;
