import React, { useState } from 'react';
import { Container, Card, Button, Row, Col, Modal } from 'react-bootstrap';
import { PersonFill, BriefcaseFill, BuildingFill } from 'react-bootstrap-icons';
import 'bootstrap/dist/css/bootstrap.min.css';

interface Membership {
  title: string;
  fees: string;
  benefit: string;
  icon: JSX.Element;
  link: string;
  description: string;
}

interface MembershipCategory {
  type: string;
  memberships: Membership[];
}

const membershipData: MembershipCategory[] = [
  {
    type: "Individual",
    memberships: [
      {
        title: "University Student",
        fees: "Annual INR 1000 | Lifetime INR 10,000 | Foreigner USD 100",
        benefit: "Research Internship",
        icon: <PersonFill />,
        link: "https://forms.gle/nUv4VeKQGtBhw3XS8",
        description: "This membership is designed for university students who are interested in research internships. Members will have the opportunity to participate in various research projects and gain valuable experience."
      },
      {
        title: "Research Scholar",
        fees: "Annual INR 5000 | Lifetime INR 50,000 | Foreigner USD 500",
        benefit: "Research Assistant",
        icon: <PersonFill />,
        link: "https://forms.gle/nUv4VeKQGtBhw3XS8",
        description: "Research Scholars will benefit from assistant roles in research projects, gaining advanced experience and networking opportunities in their field of study."
      },
      {
        title: "Working Professional",
        fees: "Annual INR 10,000 | Lifetime INR 100,000 | Foreigner USD 1,000",
        benefit: "Principal Investigator",
        icon: <BriefcaseFill />,
        link: "https://forms.gle/nUv4VeKQGtBhw3XS8",
        description: "Working professionals can become Principal Investigators, leading their own research projects and collaborating with other experts in the field."
      }
    ]
  },
  {
    type: "Corporate",
    memberships: [
      {
        title: "Small Scale Company",
        fees: "Annual INR 25,000 | Foreigner USD 2,500",
        benefit: "Management Consultancy | Staff Training | Branding Promotion",
        icon: <BuildingFill />,
        link: "https://forms.gle/nUv4VeKQGtBhw3XS8",
        description: "Small Scale Companies will receive management consultancy, staff training, and branding promotion services to enhance their business operations."
      },
      {
        title: "Medium Scale Company",
        fees: "Annual INR 50,000 | Foreigner USD 5,000",
        benefit: "Management Consultancy | Staff Training | Branding Promotion",
        icon: <BuildingFill />,
        link: "https://forms.gle/nUv4VeKQGtBhw3XS8",
        description: "Medium Scale Companies will benefit from comprehensive management consultancy, staff training programs, and strategic branding promotion."
      },
      {
        title: "Large Scale Company",
        fees: "Annual INR 100,000 | Foreigner USD 10,000",
        benefit: "Management Consultancy | Staff Training | Branding Promotion",
        icon: <BuildingFill />,
        link: "https://forms.gle/nUv4VeKQGtBhw3XS8",
        description: "Large Scale Companies will gain access to top-tier management consultancy, extensive staff training sessions, and high-impact branding promotion."
      }
    ]
  }
];

const ProfessionalMembership: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<Membership>({ title: "", fees: "", benefit: "", icon: <></>, link: "", description: "" });

  const handleShowModal = (membership: Membership) => {
    setModalContent(membership);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  return (
    <Container style={{ marginTop: "110px", fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif" }}>
      <h2 className="text-center my-5" style={{ fontWeight: 'bold', color: '#1c1c1e' }}>Professional Membership</h2>
      
      {membershipData.map((category, index) => (
        <div key={index}>
          <h3 className="text-center my-4" style={{ fontWeight: 'bold', color: '#b8860b', textTransform: 'uppercase' }}>{category.type} Membership</h3>
          <Row>
            {category.memberships.map((membership, idx) => (
              <Col md={4} className="mb-4" key={idx}>
                <Card className="h-100 shadow-sm border-0" style={{ transition: 'transform 0.2s', cursor: 'pointer', backgroundColor: '#f8f9fa', color: '#1c1c1e' }} 
                  onMouseEnter={e => e.currentTarget.style.transform = 'scale(1.05)'}
                  onMouseLeave={e => e.currentTarget.style.transform = 'scale(1)'}>
                  <Card.Body className="text-center">
                    <div style={{ fontSize: "2.5em", color: '#b8860b', marginBottom: '15px' }}>
                      {membership.icon}
                    </div>
                    <Card.Title style={{ fontWeight: 'bold' }}>{membership.title}</Card.Title>
                    <Card.Text>
                      <strong>Fees:</strong> {membership.fees}
                      <br />
                      <strong>Benefit:</strong> {membership.benefit}
                    </Card.Text>
                    <Button 
                      variant="dark" 
                      onClick={() => handleShowModal(membership)}
                      style={{ fontWeight: 'bold', backgroundColor: '#b8860b', borderColor: '#b8860b' }}
                    >
                      Learn More
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      ))}
      
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontWeight: 'bold', color: '#1c1c1e' }}>{modalContent.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: '#1c1c1e' }}>
          <p>{modalContent.description}</p>
          <p><strong>Fees:</strong> {modalContent.fees}</p>
          <p><strong>Benefit:</strong> {modalContent.benefit}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button 
            variant="dark" 
            href={modalContent.link} 
            target="_blank" 
            rel="noopener noreferrer"
            style={{ fontWeight: 'bold', backgroundColor: '#b8860b', borderColor: '#b8860b' }}
          >
            Apply 
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default ProfessionalMembership;
