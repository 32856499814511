import React from 'react';
import { Container, ListGroup, Button } from 'react-bootstrap';

interface Notification {
    id: number;
    message: string;
    read: boolean;
    timestamp: string; // Assuming timestamp is a string for simplicity
}

interface NotificationsProps {
    notifications: Notification[];
    markAsRead: (id: number) => void; // Function to mark notification as read
    deleteNotification: (id: number) => void; // Function to delete notification
}

const Notifications: React.FC<NotificationsProps> = ({ notifications, markAsRead, deleteNotification }) => {
    const handleMarkAsRead = (id: number) => {
        markAsRead(id);
    };

    const handleDelete = (id: number) => {
        deleteNotification(id);
    };

    return (
        <Container className="mt-5 pt-5">
            <h2>Notifications</h2>
            <ListGroup>
                {notifications.map((notification) => (
                    <ListGroup.Item
                        key={notification.id}
                        variant={notification.read ? "light" : "danger"}
                        action={!notification.read}
                        onClick={() => handleMarkAsRead(notification.id)}
                    >
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                {notification.message}
                                {!notification.read && (
                                    <span className="badge badge-danger ml-2">New</span>
                                )}
                                <p className="small mt-1 mb-0 text-muted">{notification.timestamp}</p>
                            </div>
                            <Button variant="outline-danger" size="sm" onClick={() => handleDelete(notification.id)}>Delete</Button>
                        </div>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </Container>
    );
};

export default Notifications;
