import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Alert, Form } from 'react-bootstrap';
import { Globe, Search, Lightbulb, GraphUp, X, People,InfoCircleFill, PeopleFill,PersonFill } from 'react-bootstrap-icons';
import Usha from '../Images/Usha.jpeg'
import Priyanka from '../Images/Priyanka.jpeg'
import Angad from "../Images/Angad.jpeg"
import Dhanush from '../Images/Dhanush.jpeg'
// Define TextAlign type
type TextAlign = 'left' | 'center' | 'right' | 'justify' | 'initial' | 'inherit';

// Define a type for heroStyle
interface HeroStyle {
  backgroundImage: string;
  backgroundRepeat: string;
  backgroundPosition: string;
  backgroundSize: string;
  padding: string;
  color: string;
  textAlign?: TextAlign; // Make textAlign optional
}

const Home: React.FC = () => {
  const [showAd, setShowAd] = useState(true);
  const [adContent, setAdContent] = useState(1);

  useEffect(() => {
    if (adContent === 1) {
      const timer = setTimeout(() => {
        setAdContent(2);
      }, 10000);
      return () => clearTimeout(timer);
    } else if (adContent === 2) {
      const timer = setTimeout(() => {
        setAdContent(3);
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [adContent]);
  // Define heroStyle as HeroStyle type
  const heroStyle: HeroStyle = {
    backgroundImage: `url('https://news.uchicago.edu/sites/default/files/styles/full_width/public/images/2023-07/Human%20aware%20AI%20hero.png?itok=BL5ceKsp')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    padding: '100px 0',
    color: '#fff',
    textAlign: 'center', // Assign a valid TextAlign value here
  };

  const sectionStyle = {
    paddingTop: '80px',
    paddingBottom: '80px',
  };

  const cardStyle = {
    padding: '20px',
  };

  // Object for researchers with image URLs
  const researchers = [
    {
      name: 'Dr. Usha Jinendra',
      image: Usha,
      expertise: {
        title: 'Research Supervisor',
        field: 'Chemical Sciences',
      },
    },
    {
      name: 'Dr. Priyanka Sinha',
      image: Priyanka,
      expertise: {
        title: 'Research Supervisor',
        field: 'Biological Sciences',
      },
    },
  ];
  
  const students = [
    {
      name: 'Mr. Angad',
      image: Angad,
      program: 'Deep-Learning',
      location: 'Maharashtra, India',
      researchInterest: 'Research-Intern: Deep-Learning.',
    },
    {
      name: 'Mr. Dhanush',
      image: Dhanush,
      program: 'Crop-Production',
      location: 'Tamilnadu, India',
      researchInterest: 'Research-Intern: Crop-Production.',
    },
  ];
  
  const ResearcherCard: React.FC<{ name: string; image: string; expertise: { title: string; field: string; } }> = ({ name, image, expertise }) => (
  <Col md={4} className="mb-4 d-flex justify-content-center">
    <Card className="h-100 shadow-lg">
      <div style={{ paddingTop: '10px' }}>
        <Card.Img
          variant="top"
          src={image}
          alt={`${name}'s picture`}
          style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '50%' }}
        />
      </div>
      <Card.Body className="text-center">
        <Card.Title>{name}</Card.Title>
        <Card.Text>
          <strong>{expertise.title}: </strong>
          {expertise.field}
        </Card.Text>
      </Card.Body>
    </Card>
  </Col>
);


  const StudentCard: React.FC<typeof students[0]> = ({ name, image, program, location, researchInterest }) => (
    <Col md={4} className="mb-4">
      <Card className="h-100 shadow-lg">
        <Card.Header>{name}</Card.Header>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '10px' }}>
          <Card.Img
            variant="top"
            src={image}
            alt={`${name}'s picture`}
            style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '50%' }}
          />
        </div>
        <Card.Body>
          {/* <Card.Text><strong>Program:</strong> {program}</Card.Text> */}
          <Card.Text><strong>Location:</strong> {location}</Card.Text>
          <Card.Text><strong>{researchInterest}</strong> </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
  
  return (
    <>

      <div className="hero-section text-white" style={heroStyle}>
        <Container>
          <Row>
            <Col>
              <h1 className="display-4 animate__animated animate__fadeInDown">Welcome to GIAR</h1>
              <p className="fw-8 animate__animated animate__fadeInUp">Connecting Universe</p>
              <Button variant="primary" size="lg" className="mt-3 animate__animated animate__fadeInUp">Explore Research</Button>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="ad-container" style={{marginTop:"20px"}}>
      {showAd && (
        <>
          {adContent === 1 && (
            <Alert variant="info" onClose={() => setShowAd(false)} dismissible className="ad-alert">
              <div className="ad-heading">
                <InfoCircleFill className="ad-icon" />
                <span className="ad-title">Join Our Editorial Team!</span>
              </div>
              <p className="ad-text">
                We are inviting applications for the position of <strong>Editor-in-Chief</strong> for our upcoming journals:
              </p>
              <ul className="ad-list">
                <li>GIAR: International Journal of Biological Sciences</li>
                <li>GIAR: International Journal of Physical Sciences</li>
                <li>GIAR: International Journal of Chemical Sciences</li>
                <li>GIAR: International Journal of Mathematical Sciences</li>
                <li>GIAR: International Journal of Environmental Sciences</li>
              </ul>
              <p className="ad-text">
                <strong>Preference will be given to:</strong>
              </p>
              <ul className="ad-list">
                <li>Scientists from CSIR-Labs</li>
                <li>Professors from Central Universities</li>
              </ul>
              <p className="ad-contact">
                For more information, <strong>WhatsApp</strong> us at <a href="https://wa.me/919981964371">+91-9981964371</a>.
              </p>
            </Alert>
          )}
          {adContent === 2 && (
            <Alert variant="warning" onClose={() => setShowAd(false)} dismissible className="ad-alert">
              <div className="ad-heading">
                <PeopleFill className="ad-icon" />
                <span className="ad-title">Urgent Hiring in Delhi/NCR</span>
              </div>
              <p className="ad-text">
                We are looking for dynamic individuals for the following positions:
              </p>
              <ul className="ad-list">
                <li>Director (HR)</li>
                <li>Placement Manager</li>
                <li>Dean of Management</li>
              </ul>
              <p className="ad-contact">
                For more information, <strong>WhatsApp</strong> us at <a href="https://wa.me/919981964371">+91-9981964371</a>.
              </p>
            </Alert>
          )}
          {adContent === 3 && (
            <Alert variant="success" onClose={() => setShowAd(false)} dismissible className="ad-alert">
              <div className="ad-heading">
                <PersonFill className="ad-icon" />
                <span className="ad-title">Exciting Opportunity in Madhya Pradesh!</span>
              </div>
              <p className="ad-text">
                Applications invited for the position of <strong>Additional Research Ambassador (GIAR: ARA)</strong> for Madhya Pradesh.
              </p>
              <p className="ad-text">
                This is a temporary and honorary position, with preference given to residents of Madhya Pradesh.
              </p>
              <p className="ad-contact">
                For more information, <strong>WhatsApp</strong> us at <a href="https://wa.me/919981964371">+91-9981964371</a>.
              </p>
            </Alert>
          )}
        </>
      )}
    </div>


      <Container style={sectionStyle}>
        <h2 className="text-center mb-5 animate__animated animate__fadeIn">International Research Overview</h2>
        <Row className="text-start">
          <Col md={12} lg={12} className="mb-4">
            <Card className="h-100 shadow-lg" style={cardStyle}>
              <Card.Body className="justify-text animate__animated animate__fadeInLeft">
                <div className='text-center'>
                  <Globe size={50} className="mb-3 text-primary" />
                </div>

                <Card.Title className='text-center'>Introduction</Card.Title>
                <p>
                  Philosophy is the mirror of all sciences, accordingly scientific experiments act exactly the same as mirror for a theory. Experimental activities are the processes of justifying relevance of concepts and ideas systematically.
                  <hr />

                  This organization is intended for spreading the significance of experiments and investigations for solving major challenges of the society. We have started our journey since July 2014 from a small town of Madhya Pradesh and now we are unveiling our flags in various continents around the world.
                  <hr />
                  Our long term goal is to become a world class research organization, where philosophers and technologists are continuously contributing their valuable efforts for shaping the organization universally.
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={3} className="mb-4">
            <Card className="h-100 shadow" style={cardStyle}>
              <Card.Body className="justify-text">
                <Search size={50} className="mb-3 text-success" />
                <Card.Title>Objectives</Card.Title>
                <p>Key objectives include:</p>
                <ul className="custom-bullets text-left mt-3">
                  <li>Fulfilling Dreams</li>
                  <li>Promoting Research</li>
                  <li>Connecting Universe</li>
                  <li>Creating Entrepreneurs</li>
                  <li>Strengthening Relationship</li>
                </ul>
              </Card.Body>
            </Card>
          </Col>

          <Col md={6} lg={3} className="mb-4">
            <Card className="h-100 shadow" style={cardStyle}>
              <Card.Body className="justify-text">
                <Lightbulb size={50} className="mb-3 text-warning" />
                <Card.Title>Innovations</Card.Title>
                <p>
                  Leading to breakthroughs in technology, medicine, and environmental science, driving global progress.
                </p>
              </Card.Body>
            </Card>
          </Col>

          <Col md={6} lg={3} className="mb-4">
            <Card className="h-100 shadow" style={cardStyle}>
              <Card.Body className="justify-text">
                <People size={50} className="mb-3 text-danger" />
                <Card.Title>Collaborations</Card.Title>
                <p>
                  Enhancing knowledge sharing and fostering mutual understanding through international partnerships.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <h2 className="text-center my-4"> Research-Supervisors</h2>
        <Row className="text-center">
          {researchers.map((researcher, index) => (
            <ResearcherCard key={index} {...researcher} />
          ))}
        </Row>

        <h2 className="text-center my-4">Research-Interns</h2>
        <Row className="text-center">
          {students.map((student, index) => (
            <StudentCard key={index} {...student} />
          ))}
        </Row>
        <section className="my-5 text-center">
          <h2>Join Us</h2>
          <p className="justify-text">If you are interested in joining our research initiatives, please fill out the form below:</p>
          <Form className="px-4 py-3 border rounded shadow-sm bg-light">
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridName">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Enter your name" />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="Enter your email" />
              </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlId="formGridResearch">
              <Form.Label>Research Interest</Form.Label>
              <Form.Control placeholder="Enter your research interest" />
            </Form.Group>

            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </section>
      </Container>
    </>
  );
}

export default Home;
