import React, { useState, useEffect } from 'react';
import { Container, Nav, Navbar, Offcanvas, Image, Badge, Button } from 'react-bootstrap';
import { Envelope, Facebook, Instagram, Linkedin, Twitter, Whatsapp, BellFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import Logo from "./logo.jpg";
import "../App.css";

const NavBar: React.FC = () => {
    const [show, setShow] = useState<boolean>(false);
    const [unreadCount, setUnreadCount] = useState<number>(0);
    const [notifications, setNotifications] = useState<{ id: number, message: string, read: boolean }[]>([]);

    useEffect(() => {
        // Simulating fetching notifications from a server or local storage
        const fetchNotifications = () => {
            const fetchedNotifications = [
                { id: 1, message: 'New Internship Available!', read: false },
                { id: 2, message: 'Meeting at 5 PM', read: false }
            ];
            setNotifications(fetchedNotifications);
            const unread = fetchedNotifications.filter(notice => !notice.read).length;
            setUnreadCount(unread);
        };

        fetchNotifications();
    }, []);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleHomeClick = () => {
        handleClose();
        if (window.location.pathname !== "/") {
            window.location.href = "/";
        } else {
            window.location.reload();
        }
    };

    const handleNotificationClick = () => {
        const updatedNotifications = notifications.map(notice => ({ ...notice, read: true }));
        setNotifications(updatedNotifications);
        setUnreadCount(0);
    };

    return (
        <>
            {['sm'].map((expand) => (
                <Navbar expand={expand} className="custom-navbar-bg mb-3 fixed-top" key={expand}>
                    <Container fluid>
                        <div className="d-flex align-items-center w-100">
                            <Navbar.Brand href="#" className='navbar-brand-customise ms-2 flex-grow-1'>
                                <Image src={Logo} height={40} roundedCircle className='me-2' />
                                GoldSmith Institute of Advanced Research
                            </Navbar.Brand>
                            {/* <Nav.Link as={Link} to={"/notifications"} className='me-2 position-relative' onClick={handleNotificationClick}>
                                <BellFill size={25} className="text-dark" />
                                {unreadCount > 0 && (
                                    <Badge pill bg="danger" className="position-absolute top-0 start-100 translate-middle">
                                        {unreadCount}
                                    </Badge>
                                )}
                            </Nav.Link> */}
                            <Navbar.Toggle className='shadow-none border-0' aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={handleShow} />
                        </div>
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="end"
                            show={show} onHide={handleClose}
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} className='navbar-brand-custom-one'>
                                    <Image src={Logo} height={45} roundedCircle className='me-2 ' />
                                    GoldSmith Institute of Advanced Research
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="justify-content-start flex-grow-1 pe-3 gap-1">
                                    <Nav.Link onClick={handleHomeClick} className='text-start btn custom-btn-primary px-2 py-2 rounded-md text-black fw-5 mt-1 md:mt-0'>Home</Nav.Link>
                                    <Nav.Link as={Link} onClick={handleClose} to={"/about"} className='text-start btn custom-btn-primary px-2 py-2 rounded-md text-black font-weight-bold mt-1 md:mt-0'>About </Nav.Link>
                                    <Nav.Link as={Link} onClick={handleClose} to={"/professional-membership"} className='text-start btn custom-btn-primary px-2 py-2 rounded-md text-black font-weight-bold mt-1 md:mt-0'>Membership</Nav.Link>
                                    <Nav.Link as={Link} onClick={handleClose} to={"/internship"} className='text-start btn custom-btn-primary px-2 py-2 rounded-md text-black font-weight-bold mt-1 md:mt-0'>Internship</Nav.Link>
                                    <Nav.Link as={Link} onClick={handleClose} to={"/research"} className='text-start btn custom-btn-primary px-2 py-2 rounded-md text-black font-weight-bold mt-1 md:mt-0'>Research</Nav.Link>
                                    <Nav.Link as={Link} onClick={handleClose} to={"/contact"} className='text-start btn custom-btn-primary px-2 py-2 rounded-md text-black font-weight-bold mt-1 md:mt-0'>Contact</Nav.Link>
                                </Nav>
                                <Nav className="justify-content-end flex-grow-1 pe-3 mt-2">
                                    <div className='d-block d-sm-none'>
                                        <hr />
                                        <p>Follow and Subscribe</p>
                                    </div>
                                    <div className='d-flex flex-sm-row flex-column'>
                                        <div className='d-flex align-items-center justify-content-start'>
                                            <a href="https://www.linkedin.com/company/giarindia/" target="_blank" rel="noopener noreferrer">
                                                <Linkedin className="fs-3 mx-1 about-icon" style={{ color: "#0A66C2" }} />
                                            </a>
                                            <a href="https://www.facebook.com/profile.php?id=100067101540565&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
                                                <Facebook className="fs-3 mx-1 about-icon" style={{ color: "#1877F2" }} />
                                            </a>
                                            <a href="https://www.instagram.com/giarindia_2014/?igsh=MjJ3Nmw5aTRpdGY0" target="_blank" rel="noopener noreferrer">
                                                <Instagram className="fs-3 mx-2 about-icon" style={{ color: "#F56040" }} />
                                            </a>
                                            <a href="https://x.com/Brijesh24482624" target="_blank" rel="noopener noreferrer">
                                                <Twitter className="fs-3 mx-2 about-icon" style={{ color: "black" }} />
                                            </a>
                                            {/* <a href="#" target="_blank" rel="noopener noreferrer">
                    <Youtube className="fs-3 mx-1 about-icon" style={{ color: "red" }} />
                </a> */}
                                        </div>
                                    </div>
                                    <div className='d-block d-sm-none'>
                                        <hr />
                                        <p>Contact & Email</p>
                                        <div className='d-flex flex-sm-row flex-column'>
                                            <Button href="https://wa.me/9981964371" target="_blank" rel="noopener noreferrer" variant="link" className="whatsapp-link">
                                                <div className='d-flex justify-content-start p-0'>
                                                    <Whatsapp className='fs-2' color='green' />
                                                    <p className='ms-2 mb-0'>WhatsApp</p>
                                                </div>
                                            </Button>

                                        </div>
                                    </div>
                                    <div className='d-block d-sm-none'>
                                        <div className='d-flex flex-sm-row flex-column'>
                                            <div className='d-flex justify-content-start p-0'>
                                                <a href="mailto:contact@giarindia.com" className='custom-email-link d-flex align-items-center'>
                                                    <Envelope className='fs-2' color='#DB4437' />
                                                    <p className='ms-2 mb-0'>Email</p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            ))}
        </>
    )
}

export default NavBar;
